import KeenSlider, { KeenSliderHooks, KeenSliderInstance, KeenSliderOptions, SliderInstance } from 'keen-slider';
import 'keen-slider/keen-slider.min.css';

const createSlider = (
  sliderId: string,
  addDots: boolean,
  otherSlider?: KeenSliderInstance<{}, {}, KeenSliderHooks>,
) => {
  const navigation = (
    slider: SliderInstance<
      KeenSliderOptions<{}, {}, KeenSliderHooks>,
      KeenSliderInstance<{}, {}, KeenSliderHooks>,
      KeenSliderHooks
    >,
  ) => {
    let dots: HTMLElement;

    const createDiv = (className: string) => {
      var div = document.createElement('div');
      var classNames = className.split(' ');
      classNames.forEach((name) => div.classList.add(name));
      return div;
    };

    const dotMarkup = (remove?: boolean) => {
      if (!addDots) return;
      if (remove) {
        if (dots && dots.parentNode) {
          dots.parentNode.removeChild(dots);
        }
        return;
      }
      const dotsContainer = document.querySelector('.slider-home__controls');
      dots = createDiv('dots');
      slider.track.details.slides.forEach((_e, idx) => {
        var dot = createDiv('slider-home__controls__btn');
        dot.addEventListener('click', () => slider.moveToIdx(idx));
        dots.appendChild(dot);
      });

      dotsContainer?.appendChild(dots);
    };

    function updateClasses() {
      if (!addDots) return;
      var slide = slider.track.details.rel;
      Array.from(dots.children).forEach(function (dot, idx) {
        idx === slide
          ? dot.classList.add('slider-home__controls__btn--active')
          : dot.classList.remove('slider-home__controls__btn--active');
      });
    }

    slider.on('created', () => {
      dotMarkup();
      updateClasses();
    });
    slider.on('optionsChanged', () => {
      dotMarkup(true);
      dotMarkup();
      updateClasses();
    });
    slider.on('slideChanged', () => {
      updateClasses();
      // Synchronize the other slider
      if (otherSlider) {
        otherSlider.moveToIdx(slider.track.details.rel);
      }
    });
    slider.on('destroyed', () => {
      dotMarkup(true);
    });
  };

  const slider = new KeenSlider(
    sliderId,
    {
      loop: true,
    },
    [
      navigation,
      (slider) => {
        let timeout: NodeJS.Timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 5000);
        }
        slider.on('created', () => {
          slider.container.addEventListener('mouseover', () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener('mouseout', () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on('dragStarted', clearNextTimeout);
        slider.on('animationEnded', nextTimeout);
        slider.on('updated', nextTimeout);
      },
    ],
  );

  return slider;
};

const homeSlider = createSlider('#slider-home', true);
const homeMobileSlider = createSlider('#slider-home-mobile', false, homeSlider);

homeSlider.on('slideChanged', () => {
  homeMobileSlider.moveToIdx(homeSlider.track.details.rel);
});
